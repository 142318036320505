
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Confirm',
    props: {
        modalIcon: {
            required: true,
            type: String
        },
        actionIcon: {
            required: true,
            type: String
        },
        modalClass: {
            required: true,
            type: String
        },
        title: {
            required: true,
            type: String
        },
        message: {
            required: true,
            type: String
        }
    },
    emits: ['result', 'close'],
    setup (_, {emit})
    {
        function close ()
        {
            emit('close');
        }

        return {close};
    }
});
