<template>
    <nav class="breadcrumb" aria-label="breadcrumbs" data-test="frm-login">
        <ul>
            <li v-for="(breadcrumb, index) in data" :key="index" :class="{'is-active': index === (data.length - 1)}">
                <router-link :to="breadcrumb.href">{{ breadcrumb.title }}</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Breadcrumbs',
    props: {
        data: {
            required: true,
            type: Array
        }
    }
});
</script>
