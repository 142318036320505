
import {defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'redirectUrl',
    props: {
        applicationUrls: {
            required: true,
            type: Array
        },
        loadingUrl: {
            required: true,
            type: Boolean
        },
        deletingUrl: {
            required: true,
            type: String
        }
    },
    setup (_, {emit})
    {
        const newUrl= ref(null);

        function addUrl (): void
        {
            emit('addUrl', {url: newUrl.value});
            newUrl.value = null;
        }

        function deleteUrl (urlId: string): void
        {
            emit('deleteUrl', urlId);
        }
        return {newUrl, addUrl, deleteUrl};
    },
});
