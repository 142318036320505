
import IdentityAppFormComponent from '@/components/admin/IdentityAppFormComponent.vue';
import RedirectUrl from '@/components/admin/RedirectUrl.vue';
import {toast} from 'bulma-toast';
import Breadcrumbs from '@/components/admin/Breadcrumbs.vue';
import Confirm from '@/components/admin/Confirm.vue';
import identity from '@/api/identity';
import {useRoute, useRouter} from 'vue-router';
import {initials, yesOrNot} from '@/tools/filters';
import {defineComponent, onMounted, reactive, toRefs} from 'vue';
import {Application, RedirectUrl as RedirectUrlType} from '@/types/Application';
import {Breadcrumb} from '@/types/System';
import {getErrorMessage} from '@/tools/handleFormErrors';
import {useStore} from 'vuex';

interface Context {
    edit: boolean;
    appDetails: Application;
    loadingApp: boolean;
    loadingUrl: boolean;
    deletingUrl: string;
    loadingDeleteApp: boolean;
    breadcrumbs: Breadcrumb [];
    showDeleteModal: boolean;
    error: boolean;
    status: number;
    allowEdit: boolean;
    display: boolean;
}

const breadHome = {title: 'Home', href: '/admin'};
export default defineComponent({
    name: 'IdentityAppDetailsComponent',
    components: {
        Confirm,
        Breadcrumbs,
        identityForm: IdentityAppFormComponent,
        RedirectUrl
    },
    setup ()
    {
        const context: Context = reactive({
            edit: false,
            appDetails: new Application(),
            loadingApp: false,
            loadingUrl: false,
            deletingUrl: '',
            loadingDeleteApp: false,
            breadcrumbs: [],
            showDeleteModal: false,
            error: false,
            status: 200,
            allowEdit: false,
            display: false
        });
        const {params: {id}} = useRoute();
        const router = useRouter();
        const store = useStore();

        if (store.state.application.myApplications.length === 0 && store.state.application.applications.length === 0)
        {
            location.href = '/admin';
        }
        else
        {
            context.display = true;
        }

        function allowsEdit (appDetail: Application)
        {
            const roles = store.state.user.roles;
            const role = roles.find((item: string) => item === 'App.Manage.All');

            if (role) context.allowEdit = true;
            if (!role && appDetail.isOwned) context.allowEdit = true;

        }

        async function getApplicationData (): Promise<void>
        {
            try
            {
                context.loadingApp = true;
                context.appDetails = store.state.application.myApplications.find((app: Application) => app.id === id);
                if (!context.appDetails) context.appDetails = store.state.application.applications.find((app: Application) => app.id === id);
                if (!context.appDetails)
                {
                    location.href = '/admin';
                }
                else
                {
                    // if (!context.appDetails) context.appDetails = (await identity.adminGetSingleApplication(id as string)).data;
                    if (!context.appDetails.hexColor.includes('#'))
                    {
                        context.appDetails.hexColor = context.appDetails.hexColor ? `#${context.appDetails.hexColor}` : '#A00095';
                    }
                    context.appDetails.secret = undefined;
                    context.breadcrumbs = [breadHome];
                    if (!context.breadcrumbs.some(breadcrumb => breadcrumb.title === context.appDetails.name))
                    {
                        context.breadcrumbs.push({
                            title: context.appDetails.name + '',
                            href: '#'
                        });
                    }
                    allowsEdit(context.appDetails);
                    context.loadingApp = false;
                }


            }
            catch (e)
            {
                context.status = e.response.status;
                if (e.response && (e.response.status === 401 || e.response.status === 403))
                {
                    context.error = true;
                }
                context.loadingApp = false;
            }
        }

        async function deleteApp (): Promise<void>
        {
            try
            {
                context.loadingDeleteApp = true;
                await identity.adminDeleteApplication(context.appDetails.id as string);
                toast({
                    message: 'Application deleted successfully',
                    type: 'is-success',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
                await router.push({name: 'AdminHome'});
            }
            catch (e)
            {
                toast({
                    message: 'Error deleting Application',
                    type: 'is-danger',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            finally
            {
                context.loadingDeleteApp = false;
            }
        }

        async function back (): Promise<void>
        {
            context.edit = false;
            await getApplicationData();
        }

        async function editApplicationData (appData: Application): Promise<void>
        {
            try
            {
                const data: Application = {...appData};
                context.loadingApp = true;
                Object.keys(data)
                    .forEach((key: string) =>
                    {
                        const appKey = key as keyof Application;
                        if (data[appKey] === null || data[appKey] === '') Reflect.deleteProperty(data, appKey);
                    });
                if (data.secret === null) Reflect.deleteProperty(data, 'secret');
                Reflect.deleteProperty(data, 'name');
                Reflect.deleteProperty(data, 'logoSrc');
                Reflect.deleteProperty(data, 'redirectUrls');
                Reflect.deleteProperty(data, 'scopes');
                Reflect.deleteProperty(data, 'isOwned');
                data.hexColor = data.hexColor.substring(1);
                context.appDetails = (await identity.adminUpdateApplication(data)).data;
                toast({
                    message: 'Application updated successfully',
                    type: 'is-success',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
                await back();
            }
            catch (e)
            {
                toast({
                    message: getErrorMessage(e, 'Application'),
                    type: 'is-danger',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            finally
            {
                context.loadingApp = false;
            }
        }

        async function addUrl (data: RedirectUrlType)
        {
            try
            {
                context.loadingUrl = true;
                const res = (await identity.adminCreateUrl(data, context.appDetails.id as string)).data;
                if (!context.appDetails.redirectUrls.some(redirect => redirect.url === data.url)) context.appDetails.redirectUrls.push(res);
                toast({
                    message: 'Url created successfully',
                    type: 'is-success',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            catch (e)
            {
                console.log('error', e);
                toast({
                    message: 'Error creating Url',
                    type: 'is-danger',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            finally
            {
                context.loadingUrl = false;
            }
        }

        async function deleteAppUrl (urlId: string): Promise<void>
        {
            try
            {
                context.deletingUrl = urlId;
                await identity.adminDeleteUrl(context.appDetails.id as string, urlId);
                context.appDetails.redirectUrls = context.appDetails.redirectUrls.filter(element => element.id !== urlId);
                toast({
                    message: 'Url deleted successfully',
                    type: 'is-success',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            catch (e)
            {
                toast({
                    message: 'Error deleting Url',
                    type: 'is-danger',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
            }
            finally
            {
                context.deletingUrl = '';
            }
        }

        function modalResult (result: boolean): void
        {
            if (result)
            {
                context.showDeleteModal = false;
                deleteApp();
            }
            else
            {
                context.showDeleteModal = false;
            }
        }

        onMounted(async () =>
        {
            await getApplicationData();
        });

        return {
            initials,
            yesOrNot,
            addUrl,
            deleteAppUrl,
            modalResult,
            editApplicationData,
            back,
            ...toRefs(context)
        };
    }
});
